.projectCard{
    border: 1px solid grey;
    border-radius: 5px;
    margin: 10px;
    /* width: 400px; */
}
.projectCard:hover{
    cursor: pointer;
    transition: all 0.5s;
    /* border: 2px solid gray; */
    scale: 1.05;
}

.projectCardSection{
   
    margin-left: 5%;
    align-items: center;
}

.lightText{
    font-size: 1.8vh;
    color: darkgray;
}

.darkText{
    font-size: 2vh;    
    color: black;
}

.leftRightDir div{
    margin-left: 10;
}

.detailsText{
    color: #AFB7BC;
    font-size: 11px;
}

.detailsValue{
   font-size: 12px;
}


.calender{
    padding: 10px;
    border-radius: 5px;
    border:1px solid #D2D2D2;
    background-color: white;
}


.calender div{
    border: none;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 10px; */
    grid-auto-rows: minmax(100px, auto);
    justify-items: center;
    height: auto;
  }

.labelFont{
    font-weight: bolder;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.togglers:hover{
    cursor: pointer;
    /* border: 2px solid #F1AD30; */
}

.text-field-Adornment {
    place-self: end;
    padding-bottom: 0.8rem;
    margin-right: -0.6rem;
  }

  .sendBtn{
     background-image: radial-gradient(rgb(246 168 34), rgb(171 114 24));
  }

  .nameText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
    margin-left: 5px
  }

  .taskBoardCommentFont{
    font-size: 0.75rem;
    color: #edab29;
    padding-left: 1.5rem;
    padding-top: 0.2rem;
    font-weight: bold;
    margin-top: 15px;
  }

  .attachmentWrapper {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 10px;
    /* grid-auto-rows: minmax(300px, auto); */
    grid-auto-rows: 250px;
    justify-items: center;
    height: auto;
    grid-template-rows: 250px;
    padding-bottom: 15px;
    grid-template-columns: 25% 25% 25% 25%;
  }

  #commentAttachmentWrapper{
    grid-template-columns: 24.2% 24.2% 24.2% 24.2%;
  }

  .uploadFile{
    max-height: 20%;
    max-width: 50%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  .uploadText{
    font-size: 0.6rem;
    position: absolute;
    top: 60%;
    left: 20%;
    margin-left: auto;
    margin-right: auto;
  }

  .upload-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
    cursor: pointer;
}

  .upload-input-project {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

  .upload-input-project-disabled {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
    cursor: default !important;
}

.attachmentOuterBox{
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
}

.deleteAtt{
    position: absolute;
    height: 23px;
    width: 10%;
    top: 5;
    right: 5;
    opacity: 1;
    z-index: 500;
}

.deleteAtt:hover{
    cursor: pointer;
}

.linkOnClick:active{
    color: -webkit-link;
}

input[type='file']{
    cursor: pointer;
    font-size: 0;
}

.horizontalLineStyle {
    opacity: 0.2
}

.horizontalLineStyle:last-child{
    visibility: hidden;
}

.identifier {
    width: 100%;
    margin-top: 10px;
}

/* .identifier:nth-child(6n+4){
    background-color: #f5f5f5;
    border-radius: 4px 0 0 4px;
}
.identifier:nth-child(6n+5){
    background-color: #f5f5f5;
}
.identifier:nth-child(6n+6){
    background-color: #f5f5f5;
    border-radius: 0 4px 4px 0;
} */

#commentTaskboard{
    width: 85.5%;
    padding: 10px;
    border: 0;
}

#TaskBoardCommentParent .MuiInput-inputMultiline{
    min-height: inherit;
    width: 87.5%;
}

#TaskBoardCommentParent .MuiInputBase-input__highlighter{
    padding: 10px;
}

#TaskBoardCommentParent{
    min-height: 100px;
    border-radius: 4px;
}

#TaskBoardCommentParent textarea {
    border: 0;
    min-height: 100px;
    padding: 10px;
    max-height: 100px;
    overflow-y: auto !important;
    padding-right: 15%;
}

#TaskBoardCommentParent textarea:focus {
    outline: auto !important;
    outline-color: rgba(0, 0, 0, 0.229) !important;
}

#TaskBoardCommentParent > div > div >div >div >div {
    padding: 10px;
    max-height: 100px;
    padding-right: calc(15% + 5px); 
    border: 0 !important;
}

#commentMention textarea {
    border: 0;
    cursor: default
}

#EditCommentMention textarea {
    border-radius: 4px;
    min-height: 30px;
    padding: 10px;
}

#EditCommentMention > div > div >div {
    padding: 10px
}

#EditCommentMention{
    background: white;
    border-radius: 4px;
    min-height: 30px;
}

#TaskBoardCommentParent > div >div >div>div:nth-child(even){
    position: absolute;
    top: -110px !important;
    left: 0 !important;
    max-height: 100px !important;
}
#customSuggestionsContainer::-webkit-scrollbar, #TaskBoardCommentParent textarea::-webkit-scrollbar{
    width: 5px;
}
#customSuggestionsContainer::-webkit-scrollbar-thumb, #TaskBoardCommentParent textarea ::-webkit-scrollbar{
    background: grey; 
    border-radius: 10px;
}
#customSuggestionsContainer::-webkit-scrollbar-track, #TaskBoardCommentParent textarea ::-webkit-scrollbar{
    box-shadow: inset 0 0 5px rgb(255, 0, 0); 
    border-radius: 10px;
}

.MentionSuggestions{
    background: white;
    border-radius: 4px;

}

.focused{
    background-color: #edab29 !important;
}

#customSuggestionsContainer{
    padding: 0px !important;
    max-height: 100px !important;
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: white !important;
    scrollbar-color: red !important
}


.activityMention textarea{
    border: 0;
    cursor: default;
    color: grey;
}

@media only screen and (max-width: 567px) {

    .SelectRangeContainer{
        display: flex;
        flex-direction: column;
    }
    
    .card{
        max-width: 300px;
        min-width: 300px;
    }

  }


  


  @media only screen and (max-width: 885px)  {

    .wrapper{
          
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(100px, auto);
      justify-items: center;
      height: auto;
      }

     
    /* .identifier:nth-child(6n+4){
    background-color: #f5f5f500;
    border-radius: 4px 0 0 4px;
    }
    .identifier:nth-child(6n+5){
        background-color: #00000000;
    }
    .identifier:nth-child(6n+6){
        background-color: #f5f5f500;
        border-radius: 0 4px 4px 0;
    }
    .identifier:nth-child(4n+3){
        background-color: #f5f5f5;
        border-radius: 4px 0 0 4px;
    }
    .identifier:nth-child(4n+4){
        background-color: #f5f5f5;
    } */

  }

  @media only screen and (max-width: 600px)  {

    .wrapper{
          
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: minmax(100px, auto);
      justify-items: center;
      height: auto;
      }

     
    /* .identifier:nth-child(6n+4){
    background-color: #f5f5f500;
    border-radius: 4px 0 0 4px;
    }
    .identifier:nth-child(6n+5){
        background-color: #00000000;
    }
    .identifier:nth-child(6n+6){
        background-color: #f5f5f500;
        border-radius: 0 4px 4px 0;
    }
    .identifier:nth-child(4n+3){
        background-color: #f5f5f500;
        border-radius: 4px 0 0 4px;
    }
    .identifier:nth-child(4n+4){
        background-color: #f5f5f500;
    }

    .identifier:nth-child(even){
        background-color: #f5f5f5;
    } */
  }


  .checkListModalDivCenter {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: 4px;
  }

  .checkListModalDivCenter tr td:last-child{
    border-right: 1px solid #cacbca
  }


  .hangover {
    max-width: 100px !important;
  }

  #ChecklistTableContainer > .MuiTable-stickyHeader{
    width: 94% !important;
  }

  :root {
    --colour1: #edab29;
    --colour2: #c99222;
    --speed: 2s;
  }

  @keyframes slide {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 113px;
    }
  }
  
.LoaderContainer {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
}
.LoaderContainer .bar {
    min-height: 7px;
    border-radius: 10px;
    width: calc(113px * 2);
    box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
    background-color: var(--colour2);
    background-image: repeating-linear-gradient(45deg, transparent, transparent 20px, var(--colour1) 20px, var(--colour1) 40px);
    animation: slide var(--speed) linear infinite;
    will-change: background-position;
}
@media only screen and (max-width: 450px) {
    .container .bar {
        width: calc(113px * 1);
   }
}
@media only screen and (max-width: 350px) {
    .container .bar {
        width: calc(113px * 1);
   }
}

#snackBarAlert > div > div > div {
    margin-bottom: 0 !important
}

.MuiMenuItem-root, .MuiTypography-body1, .MuiButton-root{
    font-family:Poppins !important;
}
  /* .makeItScrollable{
    overflow: scroll !important;
  } */

#AHJUtilTextField{
    padding: 10.5 14 !important;
}

.newBoxx{
    min-height: 200;
}
.parentCarouselContainer{
    display: flex;
    overflow-x: auto;
    background-color: #f7f8fa;
    min-height: 70vh;
    gap: 10px;
}
.parentCarouselContainer>.whatISThis>.newBoxx>.makeItScrollable>ul>li>.projectCard{
    width:350px
}
.parentCarouselContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

.parentCarouselContainer::-webkit-scrollbar-thumb {
    background: #888;
  }

  
.parentCarouselContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.parentCarouselContainer>.whatISThis>.newBoxx>.makeItScrollable{
    overflow-y: scroll;
}
.parentCarouselContainer>.whatISThis>.newBoxx>.makeItScrollable > ul{
    display: flex;
    flex-direction: column;
    max-height: 73vh;
    margin-left: 1px;
    /* width: 500px; */
    height: 100%;
    overflow-y: scroll !important;
}
.parentCarouselContainer>.whatISThis>.newBoxx>.makeItScrollable > ul > li{
    width: 80% !important;
}
.parentCarouselContainer>.whatISThis>.newBoxx{
    min-height: 300px;
}
.parentCarouselContainer>.whatISThis{
    min-width: 375px;
    margin-top: 0 !important; 
}
.parentCarouselContainer>.whatISThis>:nth-child(1){
    margin-bottom: 10px
}

#darkTheme{
    background-color: #8D8888;
    min-height: 77vh;
}
#darkTheme>.whatISThis{
    background-color: #504E4B;
    min-height: 250px;
}

#darkTheme>.whatISThis>.newBoxx>.makeItScrollable>ul>li>.projectCard{
    background-color: #965A58;
}
#darkTheme>.whatISThis>.newBoxx>.makeItScrollable>ul>li>.projectCard:hover{
    border: 1px solid gray !important;
}

.react-daterange-picker__range-divider{
    align-self: center;
}